.layout-steps {
  color: grey;
  hr {
    text-align: center;
    width: 80%;
    background-color: var(--color-secondary);
    height: 2px;
  }
  &.mobile {
    text-align: center;
    padding: 0 10%;
    .excerpt1 {
      line-height: 1.2;
      color: var(--color-primary);
      margin-bottom: 30px;
    }
    .excerpt2 {
      line-height: 22px;
      color: var(--color-secondary);
      margin-bottom: 30px;
    }
    .steps {
      display: grid;
      gap: 15px;
      margin: 0 0 30px 0;
      padding: 0;
      grid-template-columns: 1fr 1fr;
      li {
        display: block;
        line-height: 1.1;
        img {
          max-width: 80%;
          margin: 15px auto;
          display: block;
        }
      }
    }
    .steps-img {
      width: 80%;
      margin: 15px 0;
    }
    p {
      line-height: 1.1;
    }
    .logo-horizontal {
      margin-bottom: 30px;
    }
    .phone {
      margin: 30px 0;
    }
    .app-download {
      margin: 15px 0;
    }
    .app-download,
    .phone {
      a {
        color: white;
        padding: 8px 16px;
        border-radius: 5px;
        background-color: var(--color-secondary);
        display: inline-block;
      }
    }
  }
  &.desktop {
    text-align: center;
    line-height: 1.2;
    padding: 0 15%;
    .inline-logo {
      margin: 0 5px;
    }
    .steps-img {
      width: 100%;
      padding: 15px 8%;
      margin-bottom: 30px;
    }
    .excerpt1 {
      margin-bottom: 45px;
      color: var(--color-secondary);
    }
    .excerpt2 {
      line-height: 22px;
      margin-bottom: 45px;
    }
    .steps {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
      margin: 0;
      padding: 0;
      li {
        flex-grow: 1;
        display: block;
        img {
          margin-top: 30px;
          display: inline-block;
        }
      }
    }
  }
}

.layout-pic {
  &.mobile {
    text-align: center;
    line-height: 1.2;
    .wrapper {
      position: relative;
    }
    .text-container {
      position: absolute;
      bottom: 15px;
      width: 100%;
    }
    .text-wrapper {
      background: rgba($color: #ffffff, $alpha: 0.9);
      border-radius: 10px;
      padding: 10px;
      text-align: center;
      width: 80%;
      margin: 0 auto;
    }
    .next-step {
      a {
        color: white;
        padding: 8px 16px;
        border-radius: 5px;
        background-color: var(--color-secondary);
        display: inline-block;
      }
    }
    .excerpt1 {
      text-align: center;
      margin-bottom: 10px;
      line-height: 1.1;
      color: var(--color-primary);
    }

    .excerpt2 {
      margin-bottom: 10px;
      color: var(--color-secondary);
    }
    .phone {
      a {
        color: white;
        padding: 8px 16px;
        border-radius: 5px;
        background-color: var(--color-secondary);
        display: inline-block;
      }
    }
  }
  &.desktop {
    .wrapper {
      position: relative;
    }
    .text-container {
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .text-wrapper {
        border-radius: 10px;
        padding: 10px 50px;
        text-align: center;
        background: rgba($color: #ffffff, $alpha: 0.9);
      }
      .excerpt1 {
        color: var(--color-primary);
      }
      .excerpt2 {
        color: var(--color-secondary);
      }
      .phone {
        font-size: 1.4em;
        color: var(--color-primary);
      }
    }
    .text-bottom {
      color: var(--color-secondary);
      padding: 20px 0;
      text-align: center;
      .phone {
        font-size: larger;
        margin: 15px 0;
        color: var(--color-primary);
      }
    }
  }
}
.layout-qr {
  color: grey;
  hr {
    text-align: center;
    width: 80%;
    background-color: var(--color-secondary);
    height: 2px;
  }
  &.mobile {
    text-align: center;
    padding: 5%;
    line-height: 1.2;
    .excerpt1 {
      color: var(--color-primary);
    }
    .excerpt2 {
      color: var(--color-secondary);
      margin-bottom: 15px;
    }
    .phone {
      a {
        color: white;
        padding: 8px 16px;
        border-radius: 5px;
        background-color: var(--color-secondary);
        display: inline-block;
      }
    }
    .app-link {
      margin-top: 15px;
      img {
        width: 100px;
      }
    }
    .app,
    .img-wrapper {
      img {
        max-width: 160px;
      }
    }
    .phone,
    .app,
    .img-wrapper {
      margin: 30px 0;
    }
  }
  &.desktop {
    margin-top: 60px;
    padding: 2%;
    text-align: center;
    .excerpt1 {
      color: var(--color-primary);
    }
    .excerpt2 {
      color: var(--color-secondary);
    }
    .excerpt3 {
      margin-bottom: 10px;
    }
    .phone {
      color: var(--color-secondary);
      display: flex;
      flex-direction: row;
      justify-content: center;
      &:before {
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        background: url("./images/icono-tel.png");
        margin-right: 10px;
      }
    }
    .grid-wrapper {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 30px;
      margin: 30px 20%;
      text-align: left;
      .qr-wrapper {
        align-self: center;
        padding: 15px;
        img {
          max-width: 150px;
        }
      }
      .txt-wrapper {
        color: grey;
        font-size: 14px;
        border-left: 1px dotted lightgray;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        .app,
        .img-wrapper {
          text-align: center;
          img {
            max-width: 180px;
          }
        }
        div {
          margin-bottom: 30px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

//rewrites
.layout-qr.criteria-2-3 {
  h2 {
    margin-bottom: 30px;
  }
  .app {
    text-align: center;
  }
  // .phone,
  // .app {
  //   margin: 30px 0;
  //   &::before {
  //     display: none;
  //   }
  // }
}
