.static-page {
  header {
    h1 {
      max-width: 620px;
      text-align: center;
    }
    h2 {
      max-width: 500px;
    }
  }
  .content-wrapper {
    .btn {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
    .main-img img {
      width: 100%;
    }
  }
}
