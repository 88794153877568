@media screen and (min-width: 680px) {
  .logo-main {
    top: 35px !important;
  }
}

header {
  border-color: var(--color-primary);
}

footer {
  background: var(--color-primary);
}
