.App.static-page {
  header {
    .wrapper .excerpt {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        margin: 15px 0;
        h2 {
          color: var(--color-secondary) !important;
        }
      }
    }
    h1,
    h2,
    h3 {
      text-align: center;
    }
    h3 {
      max-width: 80%;
      margin: 0 auto;
      font-size: large;
      color: var(--color-secondary);
    }
  }
  .content-wrapper {
    box-shadow: none;
  }
}
