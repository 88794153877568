.wrapper {
  max-width: 1170px;
  margin: auto;
}
header {
  border-top: 15px solid var(--color-tertiary);
  .wrapper {
    position: relative;
    * {
      margin: 0;
      padding: 0;
    }
    .logo-main {
      width: 120px;
      padding: 5px;
    }
    .excerpt {
      display: none;
      h1 {
        font-size: 1.4rem;
        color: var(--color-primary);
      }
      h2 {
        font-size: 1rem;
        color: var(--color-secondary);
      }
    }
    @media screen and (min-width: 680px) {
      .logo-main {
        position: absolute;
        padding: 0;
        width: auto;
        top: 10px;
        left: 10px;
      }
      .excerpt {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        display: flex;
        height: 120px;
        padding: 0 30px 0 270px;
        h1 {
          font-size: 1.4rem;
        }
        h2 {
          font-size: 1rem;
        }
      }
    }
    @media screen and (min-width: 960px) {
      .excerpt {
        padding: 0;
        justify-content: center;
        align-items: center;
        h1 {
          font-size: 2rem;
        }
        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }
}
footer {
  font-size: 0.9rem;
  margin-top: 30px;
  padding: 15px 0;
  color: white;
  background: black;
  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }
  ul.list {
    list-style: none;
    text-align: left;
    li {
      margin-bottom: 5px;
      &:before {
        content: "\e628";
        font-family: themify;
        font-size: 12px;
        color: #058fcc;
        margin-right: 5px;
      }
    }
  }
  ul.soc {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      a {
        text-decoration: none;
        text-align: center;
        display: block;
        position: relative;
        z-index: 1;
        width: 35px;
        height: 35px;
        font-size: 19px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        border-radius: 100px;
        margin-right: 7px;
        color: #fff;
        background-color: #058fcc;
        padding-top: 3px;
      }
      .fa-facebook-f::before {
        font-family: "Font Awesome 5 Brands";
        content: "\f39e";
      }
    }
  }
}
.content-wrapper {
  @media screen and (min-width: 768px) {
    border-radius: 5px;
    box-shadow: 4px 3px 30px 0px #61616152;
  }
  .information {
    display: flex;
    flex-direction: column;
    .steps {
      padding: 30px;
      background-color: #f2f2f2;
      * {
        margin: 0;
        padding: 0;
      }
      h3 {
        font-size: 1rem;
        margin-bottom: 20px;
        text-align: center;
      }
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        li {
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
          font-size: 0.7rem;
          font-weight: bold;
          line-height: 1.2;
          .step {
            font-size: 2rem;
            font-weight: bold;
            color: var(--color-primary);
          }
          .ico {
            display: block;
            height: 60px;
            width: 60px;
            border-radius: 50% 50%;
            border: 4px solid var(--color-primary);
            margin-bottom: 15px;
            background: blue;
            background-color: white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 70%;
          }
        }
      }
    }
    .img-main {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 45vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .excerpt {
        background: #ffffffe0;
        width: 85%;
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 15px;
        text-align: center;
        position: relative;
        * {
          margin: 0;
          padding: 0;
          line-height: 1.2;
        }
        &:after {
          display: flex;
          content: "\2794";
          color: white;
          border-radius: 50% 50%;
          width: 40px;
          height: 40px;
          background: var(--color-secondary);
          transform: rotate(450deg);
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: -30px;
          left: 45%;
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
        h3 {
          font-size: 1.1rem;
          margin-bottom: 10px;
        }
        p {
          font-size: 0.8rem;
        }
      }
    }
    @media screen and (min-width: 768px) {
      flex-direction: column-reverse;
      overflow: hidden;
      .steps {
        display: none;
      }
      .img-main {
        height: 100%;
        align-items: flex-end;
        .excerpt {
          margin: 30px;
          width: auto;
        }
      }
    }
  }
  .loader-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }
}
.grid-container {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: auto 480px;
    height: 70vh;
  }
  .steps-container {
    padding: 30px 15px;
    overflow-y: scroll;
    .checkboxes-container {
      display: grid;
      grid-template-columns: auto auto;
      .invalid-feedback {
        display: block;
        grid-column: 1 / -1;
      }
    }
    @media screen and (min-width: 768px) {
      padding: 30px;
    }
  }
}
.accept-cookies {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000ad;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  z-index: 99999999;
  .wrapper {
    margin: auto;
    max-width: 70%;
    background: white;
    border-radius: 5px;
    text-align: center;
    padding: 5%;
  }
}
.steps-container {
  .form-control {
    text-overflow: ellipsis;
  }
  .checkbox-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
  .accept-terms {
    font-size: 0.8rem;
    line-height: 1.1;
    background: #e8e8e8;
    .form-check-input {
      margin-top: 0;
    }
    .invalid-feedback {
      display: block;
      grid-column: 1 / -1;
    }
    .more {
      text-align: right;
    }
  }
}
.final-screen {
  text-align: center;
  padding: 5%;
  h1 {
    color: var(--color-secondary);
  }
}
.required-field {
  font-weight: bold;
  margin-left: 5px;
}
